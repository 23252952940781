import { dashboard } from 'menu-items/dashboard';
import { utilities } from 'menu-items/utilities';

const menuItems = {
    items: [
        dashboard, 
        utilities, 
    ]
};

export default menuItems;
