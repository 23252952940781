import React, { lazy } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';

// routes
import MainRoutes from 'routes/MainRoutes';
import LoginRoutes from 'routes/LoginRoutes';

// project imports
import config from 'config';

const Auth = Loadable(lazy(() => import('views/Auth')));
const CheckEmail = Loadable(lazy(() => import('views/CheckEmail')));
//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to={config.defaultPath} />
            <Route path="/auth" component={Auth} />
            <Route path="/info" component={CheckEmail} />
            <React.Fragment>
                <LoginRoutes />

                <MainRoutes />
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
