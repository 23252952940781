import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from 'store/customizationReducer';
import accountReducer from 'store/accountReducer';
import cardReducer from 'store/cardReducer';
import apiReducer from 'store/apiReducer';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage,
            keyPrefix: 'quantria-'
        },
        accountReducer
    ),
    customization: customizationReducer,
    cards: cardReducer,
    apicards: apiReducer
});

export default reducer;
