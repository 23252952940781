// project imports
import config from 'config';

// action - state management
import * as actionTypes from 'store/actions';

export const initialState = {
    data: [{}]
};

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DATA_API:
            const data_curr = action.data;
            return {
                ...state,
                data: data_curr
            };
        default:
            return state;
    }
};

export default apiReducer;
