import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGOUT } from 'store/actions';

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */

const AuthGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    const { isLoggedIn } = account;
    if (account.token!=""){
        if (JSON.parse(atob(account.token.split(".")[1])).exp < Date.now() / 1000) {
            dispatcher({
                type: LOGOUT
            });
        }
    }
    else if (!isLoggedIn) {
        return <Redirect to="/login" />;
    }

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
